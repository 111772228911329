body {
  margin: 0px;
}

.no-scroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar {
  width: 2px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: #dddddd;
  /*box-shadow: inset 0 0 5px #dddddd;*/
  border-radius: 4px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 4px;
}

/*
Fonts
*/

@font-face {
  font-family: "ZillaSlab";
  src: local("ZillaSlab"),
   url("./assets/fonts/Zilla_Slab/ZillaSlab-Bold.ttf") format("truetype");
  font-weight: bold;
 }


 @font-face {
  font-family: "ZillaSlab";
  src: local("ZillaSlab"),
   url("./assets/fonts/Zilla_Slab/ZillaSlab-Regular.ttf") format("truetype");
  font-weight: 400;
 }


 @font-face {
  font-family: "ZillaSlab";
  src: local("ZillaSlab"),
   url("./assets/fonts/Zilla_Slab/ZillaSlab-Medium.ttf") format("truetype");
  font-weight: 500;
 }